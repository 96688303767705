<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row mb-6">
      <div class="inline-block mr-5" v-bind:class="{ active: currentTab == 1 }">
        <label class="cursor-pointer" @click="selectTab(1)">global resposes</label>
      </div>
      <div class="inline-block ml-5" v-bind:class="{ active: currentTab == 2 }">
        <label class="cursor-pointer" @click="selectTab(2)">custom responses</label>
      </div>
    </div>
    <div class="flex-1 flex-col overflow-auto">
      <div v-if="currentTab === 1" class="flex-1 flex-col overflow-auto">
          <List
          resource="allGlobalResponses"
          :fields="globalFields"
          ref="list_global"
          :pageSize="18"
          trackBy="intent_response_id"
          :search="true"
          :clickable="true"
          :key="isUpdated"
          :isSelected="intentResponseId.length > 0"
          @rowData="getResponse"
          :requestParams="{sortField: '', direction: ''}"
        >
          <template  v-slot:status="data">
            <icon name="checkmark" v-if="data.status === 'translated'" class="h-6 w-6 inline mr-4 text-green-500"></icon>
            <icon name="cancel" v-else-if="data.status === 'notTranslated'" class="h-6 w-6 inline mr-4 text-green-500"></icon>
            <icon name="clock" v-else class="h-6 w-6 inline mr-4 text-yellow-300"></icon>
          </template>
        </List>
      </div>
      <div v-else>
       <List
          resource="allCustomResponses"
          :fields="customFields"
          ref="list_custom"
          :pageSize="18"
          trackBy="intent_response_id"
          :search="true"
          :clickable="true"
          :key="isUpdated"
          :isSelected="intentResponseId.length > 0"
          @rowData="getResponse"
          :requestParams="{sortField: '', direction: ''}"
      >
          <template  v-slot:status="data">
            <icon name="checkmark" v-if="data.status === 'translated'" class="h-6 w-6 inline mr-4 text-green-500"></icon>
            <icon name="cancel" v-else-if="data.status === 'notTranslated'" class="h-6 w-6 inline mr-4 text-green-500"></icon>
            <icon name="clock" v-else class="h-6 w-6 inline mr-4 text-yellow-300"></icon>
          </template>
        </List>
      </div>
    </div>
    <div class="items-center" v-if="isDisplay">
      <div class="border border-gray-200">
        <div class="flex justify-between bg-gray-200">
          <label for="responseText" class="p-2 block">
            <span>translate response</span>
          </label>

          <div class="flex">
            <div class="flex mr-4 my-1">
              <button
                @click="() => {this.translateTab=false}"
                :class="{activeCatg: !translateTab}"
                class="btn-responses-type bg-white text-gray-500 focus:outline-none py-1 px-4 font-semibold border color-border">
                english
              </button>
              <button
                @click="() => {this.translateTab=true}"
                :class="{activeCatg: translateTab}"
                class="btn-responses-type bg-white text-gray-500 focus:outline-none py-1 px-4 font-semibold border color-border"
                >
                spanish
              </button>
            </div>
          </div>
        </div>

        <textarea v-if="!translateTab"
          id="responseText"
          name="responseText"
          placeholder="type response..."
          class="resize-none w-full focus:outline-none p-2 bg-white"
          rows="3"
          v-model="responseText"
        />

         <textarea v-else
          id="spanishResponseText"
          name="spanishResponseText"
          placeholder="Type response..."
          class="resize-none w-full focus:outline-none p-2 bg-white"
          rows="3"
          v-model="spanishResponseText"
        />

        <div class="block text-right leading-3">
          <button
            class="btn-primary btn-transparent float-left"
            @click.stop="cancel"
          >
            cancel
          </button>
          <button class="btn-primary ml-5" @click="onEdit()">
            save response
          </button>
        </div>
      </div>
      <span v-if="hasError('responseText')" class="form-error">
        {{ showError('responseText') }}
      </span>
      <span v-if="hasError('spanishResponseText')" class="form-error">
        {{ showError('spanishResponseText') }}
      </span>
    </div>
    <modal-footer :footer="footer" :primary="onDownload"/>
  </div>

</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import List from '@/components/digital_human/List'
import TranslateResponsesMixin from '@/components/digital_human/TranslateResponsesMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import Icon from "@/components/ui/Icon";
import EventBus from "@/utils/EventBus"

export default {
  name: 'CreateResponse',
  components: { Loader, List, Icon, ModalFooter },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, TranslateResponsesMixin],
   data() {
    return {
      isDisplay: false,
      fitleExtention: '',
      currentTab: 1,
      translateTab: false,
      request: '',
      intentResponseId: '',
      responseText: '',
      spanishResponseText: '',
      isUpdated: false,
      footer: {
        primaryButton: 'export to excel',
      },
      globalFields: [
        {
          name: 'intent_name',
          title: 'intent name',
          sortField: 'intent_name'
        },
        {
          name: 'response_text',
          title: 'response',
          sortField: 'response_text'
        },
        {
          name: 'status',
          title: 'status',
          sortField: 'status'
        }
      ],
      customFields: [
        {
          name: 'intent_name',
          title: 'intent name',
          sortField: 'intent_name'
        },
        {
          name: 'response_text',
          title: 'response',
          sortField: 'response_text'
        },
        {
          name: 'status',
          title: 'status',
          sortField: 'status'
        }
      ]
    }
  },
  methods: {
    selectTab(nextTab) {
      if(this.intentResponseId.length > 0) {
        return;
      }
      if (this.currentTab !== nextTab) {
        this.currentTab = nextTab;
        this.isDisplay = false;
        this.translateTab = false;
      }
    },
    getResponse(row) {

     this.request = 'allGlobalResponsesByResponseId'

      this.intentResponseId = row['data'].intent_response_id;

      this.setLoading(true)
      this.$dhDataProvider
        .get(this.request, { responseId: this.intentResponseId })
        .then((res) => {
          this.responseText = res.response_text;
          this.spanishResponseText = res.spanish_response_text;
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
          this.isDisplay = true
          this.translateTab = false
          this.setLoading(false)
        })
    },
    onEdit() {
      if (this.validator.passes()) {
        this.setLoading(true)
        this.$dhDataProvider
          .update('response', {
            id: this.intentResponseId,
            data: {
              response_text: this.responseText,
              spanish_response_text: this.spanishResponseText
            },
          })
          .then(() => {
            this.notifySuccess('The response was updated successfully')
            this.isUpdated = true;
          })
          .catch((err) => this.notifyError(err.message))
          .finally(() => {
            this.cancel()
            this.intentResponseId = '';
            this.isUpdated = false;
            this.setLoading(false)
          })
      }
    },
    cancel(){
        this.intentResponseId = '';
        this.isDisplay = false;
        this.translateTab = false;
    },
    download(extention) {
        this.fitleExtention = extention;
        if (this.fitleExtention !== '')
          EventBus.emit('show-modal-download', { title: 'Download PDF', downloadStarted: 'download started'});
    },
    downloadProcess(data, extentionFile) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `translate-response-report.${extentionFile}`);
      document.body.appendChild(link);
      link.click();
    },
    generateExcelReport() {
      this.$dhDataProvider.get('translateReport', { responseType: 'blob',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-disposition': 'attachment',
          'format_file': 'xlsx'
        }})
        .then(response => {
          if (this.fitleExtention.trim() !== '') {
            this.fitleExtention = ''
            this.downloadProcess(response, 'xlsx');
          }
        })
        .catch((error) => this.notifyError(error))
        .finally(() => this.hideDownloadIndicator())
    },
    onDownload() {
      this.download('xlsx')
    },
  },
  validations: {
    responseText: 'required | is_empty',
    spanishResponseText: 'is_empty',
  },
  created() {
    this.initValidator()
    this.validator.extend('is_empty', function(value) {
      return !value.match(/^\s+$/)
    })
    this.validator.setCustomErrorMessages({
      'responseText.is_empty': 'this field is empty.',
      'spanishResponseText.is_empty': 'this field is empty.',
    })
  },
  mounted() {
    EventBus.on('download-pdf', () => {
      switch (this.fitleExtention) {
        case 'xlsx':
          this.generateExcelReport()
          break;
        default:
          this.fitleExtention = ''
          break;
      }
    })
  },
}

</script>

<style scoped>
.form {
  width: 55%;
}
.label-container {
  width: 53%;
}
.active {
  color: var(--highlightColor500);
  border-bottom: 2px solid;
  border-color: var(--highlightColor500);
  margin: 0 5px 0 5px;
}
.activeCatg {
  background-color: var(--highlightColor500);
  @apply text-white border-transparent;
}
</style>
